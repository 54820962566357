<template>
    <div class="view-script-main">
        <div class="breadcrumb-item">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="interactive-breadcrumb">
                <el-breadcrumb-item class="first">创作中心</el-breadcrumb-item>
                <el-breadcrumb-item class="second">脚本创作</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="back-btn">
                <el-button size="medium" class="blue-btn" @click="backBtn">返回</el-button>
            </div>
        </div>
        <div class="view-script-content">
            <div class="view-script-item">
                <div class="view-script-item-top">
                    <div class="view-script-img">
                        <img :src="addScriptForm.cover_url" alt="">
                    </div>
                    <div class="view-script-right">
                        <div class="view-script-name">脚本名称：<span>{{addScriptForm.name}}</span></div>
                        <div class="view-script-fenlei">脚本分类：<span>{{addScriptForm.classification_name}}</span></div>
                        <div class="view-script-jianjie" v-show="addScriptForm.script_introduction!==''">脚本简介：<span>{{addScriptForm.script_introduction}}</span></div>
                    </div>
                </div>
                <div class="view-script-body" v-show="addScriptForm.script_content !==''">
                    <div class="view-script-body-title">脚本内容</div>
                    <div class="view-script-body-content" v-html="addScriptForm.script_content"></div>
                </div>
                <div class="view-script-footer">
                    <el-table :data="addScriptForm.camera_lens" style="width: 100%; flex: 1"
                              size="medium"
                              :header-cell-style="{fontWeight: 'normal', color: '#222', background: '#D3DDFF',height:'50px'}"
                              :cell-style="{fontSize: '14px',color: '#222',height:'98px'}" >
                        <el-table-column prop="number" label="镜头" align="center" width="60"></el-table-column>
                        <el-table-column prop="farewell" label="景别" align="center"></el-table-column>
                        <el-table-column prop="direction" label="拍摄方向" align="center"></el-table-column>
                        <el-table-column prop="mirror_movement" label="运镜" align="center"></el-table-column>
                        <el-table-column prop="content" label="拍摄内容" align="center" width="468"></el-table-column>
                        <el-table-column prop="music_or_subtitle" label="音乐/字幕" align="center"></el-table-column>
                        <el-table-column prop="time" label="时间" align="center"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "studentViewScriptCreation",
        data(){
            return{
                id:this.$route.query.id||'',
                addScriptForm: {
                    name: '',
                    script_introduction: '',
                    cover_url: null,
                    classification_name: '',
                    // classification_name: '',
                    script_content: '',
                    camera_lens: []
                },
            }
        },
        mounted() {
            this.getScriptList()
        },
        methods:{
            // 查看脚本详情
            getScriptList() {
                let param = {
                    status: 1,
                    id:this.$route.query.id
                }
                this.$httpStudent.axiosGetBy(this.$api.getScript, param, res => {
                        if (res.code === 200) {
                            this.addScriptForm = res.data
                            // 删除 this.addScriptForm.script_content 中所有的 ing 标签
                            let str = this.addScriptForm.script_content.replace(/<(?!img).*?>/g,'');
                            // 删除 str 中所有的 &nbsp; 字符
                            str = str.replace(/&nbsp;/g,'');
                            if(str===''||str===' '){
                                // 如果 str为空或者 str ==' '
                                this.addScriptForm.script_content='';
                            }
                            this.addScriptForm.camera_lens = res.data.camera_lens
                            this.$message({
                                type: "success",
                                message: res.message,
                                duration: 1000,
                            });
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    },
                    err => {
                        console.log(err);
                    });
            },

            backBtn() {
                this.$router.go(-1)
            },
        }
    }
</script>

<style scoped lang="scss">
.view-script-main{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    .view-script-content{
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        border-radius: 10px;
        background-color: #fff;
            .view-script-item{
                padding: 30px;
                box-sizing: border-box;
                .view-script-item-top{
                    display: flex;
                    align-items: center;
                    .view-script-img{
                        width:288px;
                        height:179px;
                        margin-right:20px;
                        img{
                            width:288px;
                            height:179px;
                        }
                    }
                    .view-script-right{
                        flex: 1;
                        width: 1%;
                        .view-script-name{
                            font-size: 20px;
                            color: #333;
                        }
                        .view-script-fenlei{
                            margin: 40px 0;
                            font-size: 16px;
                            color: #666;
                        }
                        .view-script-jianjie{
                            font-size: 16px;
                            color: #666;
                        }
                    }
                }
                .view-script-body{
                    display: flex;
                    flex-direction: column;
                    border: 2px solid #574DED;
                    box-shadow: 0px 10px 0px 0px #ECEBFF;
                    border-radius:25px;
                    margin:42px 0;
                    .view-script-body-title{
                        width: 160px;
                        height: 32px;
                        background: #584EEE;
                        border-radius: 16px;
                        line-height: 32px;
                        text-align: center;
                        color: #fff;
                        margin: -12px 0 0 24px;
                        position: relative;
                        &:before{
                            content: "";
                            background: #fff;
                            -webkit-border-radius: 50%;
                            border-radius: 50%;
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            top: 12px;
                            left: 20px
                        }
                        &:after{
                            content: "";
                            background: #fff;
                            -webkit-border-radius: 50%;
                            border-radius: 50%;
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            top: 12px;
                            right: 20px
                        }
                    }
                    .view-script-body-content{
                        display: flex;
                        min-height: 100px;
                        padding-left: 22px;
                    }
                }
                .view-script-footer{
                    margin-top: 10px;
                }
            }
    }
    ::v-deep .el-table__body tr:hover>td{
        background-color: #F0F3FF;
    }
    ::v-deep .el-table--medium td,::v-deep .el-table--medium th {
        border-right: 2px solid #fff;
    }
    ::v-deep .el-table--medium td{
        background:#F0F3FF;
        border-bottom: 2px solid #fff
    }
    .breadcrumb-item{
        display: flex;
        justify-content: space-between;
        .back-btn {
            margin-top: -12px;
        }
    }
}
</style>